@import url("../css/bootstrap.min.css");
@import url("../css/slick.css");
@import url("../css/slicktheme.css");
@import url("../css/animation.css");

@import "default/variables";
@import "default/mixins";
@import "default/reset";
@import "default/typography";
@import "default/extend";
@import "default/animations";
@import "default/shortcode";
@import "default/spacing";
@import "default/common";
@import "default/forms";

@import "color/themebg";

@import "header/header";
@import "header/nav";
@import "header/mobilemenu";
@import "header/headertop";

@import "elements/sctiontitle";
@import "elements/button";
@import "elements/breadcrumb";
@import "elements/accordion";
@import "elements/service";
@import "elements/card";
@import "elements/progressbar";
@import "elements/counter";
@import "elements/portfolio";
@import "elements/social";
@import "elements/team";
@import "elements/timeline";
@import "elements/testimonial";
@import "elements/slickslide";
@import "elements/tab";
@import "elements/pricing";
@import "elements/split";
@import "elements/callto-action";
@import "elements/video";
@import "elements/contact";
@import "elements/brand";
@import "elements/newsletter";
@import "elements/advance-tab";
@import "elements/advancepricing";
@import "elements/about";
@import "elements/swicher";
@import "elements/404";

/**************************************
    Blog Styles
***************************************/

@import "blog/blog";
@import "blog/sidebar";
@import "blog/blog-details";

/**************************************
    Footer Styles
***************************************/
@import "template/banner";
@import "template/portfoliodetails";
@import "template/splash";

/**************************************
    Footer Styles
***************************************/

@import "footer/footer";
@import "footer/copyright";
@import "footer/darkmode";
