/* Counter Style One  */

.counter-style-1 {
  .count-number {
    // @extend .theme-gradient;
    color: white;

    font-size: 90px;
    .gold-color {
      color: #b89c4c !important;
    }
    @media #{$md-layout} {
      font-size: 60px;
      margin-bottom: -5px;
      display: block;
    }
    @media #{$sm-layout} {
      font-size: 60px;
      margin-bottom: -5px;
      display: block;
    }
  }
  .title {
    font-size: 20px;
    line-height: 1.3em;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.counter-style-2 {
  margin-top: 30px;
  @media #{$large-mobile} {
    text-align: center !important;
  }
  .count-number {
    font-size: 44px;
    background-color: var(--color-blackest);
    display: inline-block;
    min-width: 150px;
    height: 150px;
    border-radius: 100%;
    text-align: center;
    line-height: 150px;
    margin-bottom: 20px;
    padding: 0 20px;
    font-weight: 500;
    color: var(--color-white);
  }

  .title {
    font-size: 20px;
    line-height: 1.3em;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .description {
    line-height: 1.8;
    padding-right: 16%;
    margin: 0;
    @media #{$large-mobile} {
      padding-right: 0;
    }
  }

  &.color-variation {
    .count-number {
      background-color: var(--color-primary-darker);
      color: var(--color-primary);
    }
  }

  &.border-style {
    .count-number {
      background-color: transparent;
      border: 2px solid var(--color-border);
    }
  }

  &.text-center {
    .description {
      padding: 0 10%;
    }
  }
}

[class*="col"]:nth-child(2) {
  .counter-style-2 {
    &.color-variation {
      .count-number {
        background-color: var(--color-secondary-darker);
        color: var(--color-secondary);
      }
    }
  }
}

[class*="col"]:nth-child(3) {
  .counter-style-2 {
    &.color-variation {
      .count-number {
        background-color: var(--color-light-green-darker);
        color: var(--color-light-green);
      }
    }
  }
}

/*------------------------------
    Counterup Style Four  
-------------------------------*/

.counter-style-4 .count-number {
  font-size: 35px;
  line-height: 1.2;
  font-weight: 700;
  color: var(--color-primary);
  display: flex; /* Changed to flex for better control */
  align-items: center; /* Center items vertically */
  position: relative;
}

// .counter-style-4 .count-number .count-up-wrapper::after {
//   content: "+";
//   margin-left: 4px; /* Adjust spacing to your liking */
// }

// .counter-style-4 .count-number h2 {
//   margin-bottom: 0; /* Eliminate bottom margin */
// }

// .counter-style-4 .counter-title {
//   margin-top: 0;
//   font-weight: 400;
//   font-size: 24px;
//   margin-left: 12px; /* Adds some space between the number and the text */
// }
.counter-style-4 {
  text-align: left;
  margin-top: 30px;
  display: flex;

  .counter-title {
    margin-top: 8px;
    font-weight: 400;
    font-size: 24px;
  }

  .count-number {
    font-size: 35px;
    line-height: 1.2;
    font-weight: 700;
    color: var(--color-primary);
    display: inline-block;
    position: relative;
    // &::after {
    //   content: "+";
    // }
    h2 {
      min-width: 25%;
    }

    span {
      display: inline-block;
    }
  }
}
