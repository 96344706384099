/*-- Variables --*/
:root {
  // Base colors
  --color-primary: #b89c4c;
  --color-primary-rgba: rgb(184, 156, 76);
  --color-primary-alt: #c1a55e;
  --color-primary-light: #c9af71;
  --color-primary-lightest: #d1b884;
  --color-secondary: #292929;
  --color-tertiary: #3e3e3e;
  --color-tertiary-alt: #555555;
  --color-pink: #ffffff;
  --color-light-green: #a4977a;
  --color-primary-darker: #000000;
  --color-tertiary-darker: #1e1e1e;
  --color-secondary-darker: #141414;
  --color-light-green-darker: #7c6f56;
  --color-pink-darker: #e6e6e6;

  // Typo Color
  --color-heading: #ffffff;

  --color-dark: #292929;
  --color-darker: rgba(41, 41, 41, 0.7);
  --color-darkest: rgba(0, 0, 0, 0.3);

  --color-black: #000000;
  --color-blacker: rgba(15, 15, 17, 0.8);
  --color-blackest: #0f0f11;
  --color-border: hsla(42, 42%, 45%, 0.3);

  --color-lessdark: #1e1e1e;
  // Grey Colors
  --color-gray: #65676b;
  --color-midgray: #878787;
  --color-light: #e4e6ea;
  --color-lighter: #ced0d4;
  --color-lightest: #f0f2f5;

  --color-white: #ffffff;

  // Notify Colors (Adjusted for new color scheme)
  --color-success: #9ca65c;
  --color-danger: #d9534f;
  --color-warning: #f0ad4e;
  --color-info: #5bc0de;

  //Social icon colors (Adjusting slightly to fit the new scheme)
  --color-facebook: #3b5998;
  --color-twitter: #1da1f2;
  --color-youtube: #c4302b;
  --color-linkedin: #007bb5;
  --color-pinterest: #cb2027;
  --color-instagram: #c13584;
  --color-vimeo: #1ab7ea;
  --color-twitch: #6441a5;
  --color-discord: #7289da;

  // Extra Color (Adjusted slightly for consistency)
  --color-extra01: #666666;
  --color-extra02: #606770;
  --color-extra03: #fbfbfd;
  --color-extra04: #1a1a1a;
  --color-extra05: #242424;

  //Border-radius
  --border-width: 2px;
  --radius-small: 6px;
  --radius: 10px;
  --radius-big: 16px;

  //Font weight
  //primary font
  --p-light: 300;
  --p-regular: 400;
  --p-medium: 500;
  --p-semi-bold: 600;
  --p-bold: 700;
  --p-extra-bold: 800;
  --p-black: 900;

  //Shadows
  --shadow-primary: 0 0 20px 5px rgba(184, 156, 76, 0.2);
  --shadow-light: 1px 1px 6px rgba(0, 0, 0, 0.25);
  --shadow-lighter: 4px 4px 20px 20px rgba(0, 0, 0, 0.01);

  //transition easing
  --transition: 0.3s;
  --transition-transform: transform 0.65s cubic-bezier(0.23, 1, 0.32, 1);

  //Font Family
  --font-primary: "Inter", sans-serif;
  --secondary-font: "Inter", sans-serif;
  --font-awesome: "Font Awesome 5 Pro";

  //Fonts Size
  --font-size-b1: 18px;
  --font-size-b2: 16px;
  --font-size-b3: 14px;
  --font-size-b4: 12px;

  //Line Height
  --line-height-b1: 1.67; //28px
  --line-height-b2: 1.7; //24px
  --line-height-b3: 1.6; //22px
  --line-height-b4: 1.3; //16px

  // Heading Font
  --h1: 56px; //52px 1.29
  --h2: 44px; //44px 1.23
  --h3: 36px; // 34px 1.14
  --h4: 24px; //30px  1.25
  --h5: 18px; //22px 1.24
  --h6: 16px; //20px 1.25
}

// Layouts Variation
$smlg-device: "only screen and (max-width: 1199px)";
$extra-device: "only screen and (min-width: 1600px) and (max-width: 1919px)";
$laptop-device: "only screen and (min-width: 1200px) and (max-width: 1599px)";
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 991px)";
$sm-layout: "only screen and (max-width: 767px)";
$large-mobile: "only screen and (max-width: 575px)";
$small-mobile: "only screen and (max-width: 479px)";
